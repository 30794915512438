.AdminPanel {
  margin: 1em;

  .AdminPanel-sections {
    display: grid;
    grid-template-columns: 75% 25%;
  }

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0 .3em;
  }

  .battery-tr {
    td::after {
      content: "%"
    }
  }

  .edit-item {
    cursor: pointer;
    padding-left: 1em;
    padding-right: 1em;
  }
  .edit-configuration {
    cursor: pointer;
  }

  // TODO: make this better lol
  button {
    margin: 1em;
  }

  .cancel-button {
    background-color: var(--lightText);
    border-color: var(--lightText);

    &:hover {
      background-color: var(--darkText);
      border-color: var(--darkText);
    }
  }

  .modal-interior {
    width: 100%;
    height: 100%;
    position: relative;

    label, input {
      display: block;
    }

    label {
      margin-top: 1em;
    }

    .modal-buttons {
      position: absolute;
      bottom: 1em;
    }
  }

  .tab {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-end;

    button {
      width: fit-content;
      // max-width: 15%;
      height: 50px;
      border: none;
      color: var(--darkText);
      background-color: rgb(244, 241, 241);
      border-radius: 10px 10px 0 0;
      -webkit-box-shadow: inset 0px 0px 18px -14px rgba(0,0,0,0.75);
      -moz-box-shadow: inset 0px 0px 18px -14px rgba(0,0,0,0.75);
      box-shadow: inset 0px 0px 18px -14px rgba(0,0,0,0.75);
      margin: 0;

      &:hover {
        color: #373739;
      }

      &.active-tab {
        background-color: white;
        box-shadow: none;
        color: var(--primary);
      }
    }
  }

  .tabcontent {
    display: none;
    padding: 6px 12px;
    border-top: none;
    background-color: var(--white);

    &.active-tab {
      display: block;
    }
  }

  .AdminPanel-right {
    position: relative;
    .AdminPanel-buttons {
      position: sticky;
      padding-top: 70px;
      top: 0;
    }
  }
}

// AMPLIFY AUTHENTICATOR

[data-amplify-authenticator][data-variation=modal] {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

[data-amplify-authenticator] {
    display: grid;
}

[data-amplify-authenticator] [data-amplify-container] {
    place-self: center;
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: white;
    box-shadow: 0px 2px 6px hsla(210, 50%, 10%, 0.15);
    border-color:#89949f;
    border-width: 1px;
    border-style: solid;
}

.amplify-tabs[data-indicator-position=top] {
    border-width: 2px 0 0 0;
}

.amplify-tabs {
    background-color: #00000000;
    // box-shadow: var(--amplify-components-tabs-box-shadow);
    border-style: solid;
    border-color: #dcdee0;
    gap: 0;
}

.amplify-tabs-item[data-spacing=equal] {
    flex: 1 1;
}

.amplify-tabs-item[data-state=active] {
    color: var(--primary);
    border-color: var(--primary);
    background-color: transparent;
    transition-property: none;
    display: none;
}

[data-amplify-authenticator] [data-state=inactive] {
    display: none;
}

[data-indicator-position=top] > .amplify-tabs-item {
    border-width: 2px 0 0 0;
    margin-top: calc(-1 * 2px);
    margin-bottom: 0;
}

.amplify-tabs-item {
    background-color: transparent;
    box-sizing: border-box;
    color: var(--primary);
    font-size: 1rem;
    font-weight: 700;
    padding: .75rem 1rem;
    text-align: center;
    transition: all 250ms;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: #dcdee0;
    margin-bottom: calc(-1 * 2px);
}

.amplify-flex {
    align-content: normal;
    align-items: stretch;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: normal;
    padding-left: .5em;
    padding-right: .5em;

    fieldset {
      margin-inline-start: 2px;
      margin-inline-end: 2px;
      padding-block-start: 0.35em;
      padding-inline-start: 0.75em;
      padding-inline-end: 0.75em;
      padding-block-end: 0.625em;
      min-inline-size: min-content;
      border: none;
    }
}

.amplify-field-group__field-wrapper {
    position: relative;
    width: 100%;
}

.amplify-field {
    gap: .5rem;
    flex-direction: column;
}

.amplify-input {
    box-sizing: border-box;
    color: #000;
    line-height: 1.5;
    padding-block-start: .5rem;
    padding-block-end: .5rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    width: 100%;
    border-color: #89949f;
    border-radius: .25rem;
    border-style: solid;
    border-width: 1px;
    outline-color: #89949f;
    outline-style: solid;
    outline-width: 0;
    outline-offset: 2px;
    -webkit-user-select: text;
    user-select: text;
    display: inline-block;
}

.amplify-field-group__outer-end .amplify-select__wrapper:not(:last-child) .amplify-select, .amplify-field-group__outer-end--quiet .amplify-field-group__control, .amplify-field-group__outer-end .amplify-field-group__control:not(:last-child), .amplify-field-group__outer-start .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-start .amplify-field-group__control, .amplify-field-group :not(:last-child) .amplify-input {
    border-end-end-radius: 0;
    border-start-end-radius: 0;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
    border-inline-start-color: transparent;
}

.amplify-field-group__outer-end .amplify-field-group__control {
    height: 100%;
}

.amplify-field-group__outer-end .amplify-select__wrapper .amplify-select, .amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child), .amplify-field-group__outer-start--quiet .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child), .amplify-field-group :not(:first-child) .amplify-input {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
    display: flex;
    padding-top: 0;
    padding-left: 10px;
}

.amplify-field-group__field-wrapper {
    position: relative;
    width: 100%;
}

.amplify-field-group--horizontal {
    flex-direction: row;
}

.amplify-field-group {
    gap: var(--amplify-components-fieldgroup-gap);
    align-self: stretch;
}

.amplify-button--link {
  background-color: transparent;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  color: var(--primary);
  border: none;

  &:hover {
    opacity: .6;
    color: var(--primary);
    background-color: transparent;
  }
}

.amplify-button {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    -webkit-user-select: none;
    user-select: none;
    --amplify-internal-button-disabled-text-decoration: initial;
    --amplify-internal-button-loading-text-decoration: initial;
}

.amplify-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    fill: transparent;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.amplify-icon {  
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  line-height: 1;
}

[data-amplify-footer] {
  display: flex;
}

@media (min-width: 30rem) {
  [data-amplify-authenticator] [data-amplify-container] {
      width: 30rem;
  }
}