.Toast {
  max-width: 100vw;
  width: 100vw;
  min-height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  position: absolute;
  top: 75px;
  right: 0;
  padding-top: 1.5em;
  z-index: 2;

  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;

  &.closed {
    visibility: hidden;
    opacity: 0;
    top: 0;
  }

  .close {
    position: absolute;
    top: .5em;
    right: .5em;
    cursor: pointer;
    padding: 5px;
  }
}