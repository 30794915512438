.form-modal {
  height: 95vh;
  width: 80%;
  max-width: 700px;
  background-color: var(--white);
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;

  .close-icon {
    position: sticky;
    top: 1em;
    margin-left: auto;
    margin-right: 1em;
    font-size: 20px;
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  h2 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .form-thank-you {
    padding: 1em;
    width: 80%;
    height: 90%;
    min-height: 400px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contact-link {
      display: flex;
      align-items: center;
      .contact-icon {
        font-size: 40px;
        padding-right: 1em;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  form {
    width: 80%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    label {
      font-weight: 600;
    }
    input {
      margin-bottom: 1em;
      width: 100%;
      background-color: var(--inputBackgroundColor);
      border-radius: var(--cardBorderRadius);
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: none;
      height: 30px;
      padding-left: .5em;
    }

    #submit {
      margin: 1em 0;
    }
  }
}