.ResultsOverview {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2em;

  .left-summary {
    width: 48%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .right-summary {
    width: 48%;
    display: flex;
    flex-direction: column;

    .summary-card {
      padding: 1em;
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .disclaimer-card {
      margin-top: 1em;
      padding: .5em;
      text-align: center;
    }
  }

  .white {
    background-color: var(--white);
  }
  p {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;

    span {
      font-size: 50px;
      line-height: 50px;
    }
  }
}

.ResultsInitial, .ResultsServiceLife {
  margin: 2em 0;
  background-color: var(--white);
  padding: 1em;

  .modal-wrapper {
    .rodal-dialog {
      height: fit-content !important;
      min-height: 200px !important;
      max-height: 450px !important;
      overflow: auto;
      font-size: 14px;
      padding-top: 3em;
    }
  }

  .card-body-wrapper {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1fr 150px 1fr;

    &.flex-wrapper {
      display: flex;
      justify-content: space-between;

      .card-inset {
        width: fit-content;
        margin-right: 2em;
      }
    }

    .card-inset {
      min-height: 200px;
      width: 100%;
      min-width: 200px;
      padding: 1em;
      height: fit-content;

      .result-card-small {
        margin-top: .8em;
        display: grid;
        grid-template-columns: 50% 24% 24% 2%;
        font-size: 10px;
        background-color: var(--white);
        // border: .5px solid gray;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        padding: .2em .4em;
        align-items: center;
        text-align: center;

        h4 {
          font-size: 12px;
          padding-right: 5px;
          text-align: left;
        }

        &.result-card-title {
          font-weight: bold;
          box-shadow: none;
        }

        .tooltip-icon {
          cursor: pointer;
        }
      }

      &.graph-card {
        // max-width: 60%;
        width: 100%;
        margin-right: 0;

        &.graph-card-large {
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }

    .transformer-icon {
      text-align: center;
      padding: 1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.ResultsServiceLife {
  .card-body-wrapper {
    .card-inset {
      .result-card-small {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

@media (max-width: 1199px) {
.ResultsInitial, .ResultsServiceLife {
  h3 {
    font-size: .9em;
  }
  .card-body-wrapper {
    grid-template-columns: 1fr 125px 1fr;
    .card-inset {
      .result-card-small {
        h4 {
          font-size: 10px;
        }
        &.result-card-title {
          font-size: 9px;
        }
      }
    }
  }
}
}