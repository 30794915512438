.NavBar {
  height: 75px;
  max-width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px 2em;
  position: sticky;
  top: 0;
  filter: drop-shadow(0 1px 2px rgba(0,0,0,.1)) drop-shadow(0 1px 1px rgba(0,0,0,.06));
  z-index: 3;
  justify-content: space-between;

  img {
    height: 100%;
    cursor: pointer;
  }
}