.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: var(--inputBackgroundColor);
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: var(--cardBorderRadius);
  max-width: 200px;
  padding: 0px 8px;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 30px;
}

.segment {
  width: 100%;
  min-width: 50px;
  position: relative;
  text-align: center;
  z-index: 1;

  label {
    cursor: pointer;
    display: block;
    font-weight: 600 !important;
    font-size: 12px !important;
    padding: 8px 10px;
    transition: color 0.5s ease;
  }
}

.segment.active label {
  color: #fff;
}

.controls {

    &::before {
    content: "";
    background: var(--primary);
    border-radius: var(--cardBorderRadius);
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 0;
    z-index: 0;
  }

  &.disabled {
    &::before {
        background-color: var(--lightText);
    }
  }
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}
