.FinalForm {
  display: flex;
  width: 100%;
  height: calc(100vh - 75px);

  .show-config {
    height: 40px;
    width: 40px;
    position: absolute;
    left: 0;
    top: 80px;
    transition: all .3s linear;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);

    &.open {
      left: calc(33.3% - 40px);
      background-color: transparent;
    }

    .open-close-icon {
      transition: all .2s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .terastar-img {
    height: calc(100% - 75px);
    // background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 33.3%;
    width: calc(100% - 33.3%);

    img {
      width: 80%;
    }
  }

  .results-wrapper {
    padding: 0;
    height: calc(100% - 75px);
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    left: 33.3%;
    transition: all .5s ease;

    &.larger {
      left: 0;
      padding-left: 1em;
    }

    .results-max-w {
      max-width: 1300px;
      margin: auto;
      width: 100%;
      padding-left: 2em;
      padding-right: 2em;

      .changeOverlay {
        position: fixed;
        z-index: 9;
        background-color: rgba(125, 127, 127, 0.7);
        height: 100%;
        width: 66.67%;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .close {
          position: absolute;
          top: 10px;
          right: 15px;
          color: white;
          cursor: pointer;
        }

        .text {
          color: white;
          font-weight: bold;
          width: 60%;
          text-align: center;
        }
      }
    }

    
  }

  .hide {
    display: none;
  }
}