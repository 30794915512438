@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  --primary: #003875;
  --darkText: #252525;
  --lightText: gray;
  --warning: #AC141A;
  // --lightText: #404040;
  // --lightestText: #666666;
  // --borders: #d0d0d0;
  --pageBackground: rgba(0,56,117,.1);
  --white: #ffffff;
  --black: #000000;
  --footerBackgroundColor: rgba(0, 57, 117, 0.713);
  --inputBackgroundColor: rgba(0, 0, 0, 0.08);
  --modalOverlay: rgba(0, 57, 117, 0.3);

  // cards
  --cardShadow: 0 20px 25px -5px rgb(0 0 0 / 10%), 0 10px 10px -5px rgb(0 0 0 / 4%);
  --insetCardShadow: inset 0px 0px 14px 3px rgba(0,0,0,.15);
  --cardBorderRadius: 3px;

  // tables
  --tableBoxShadow: inset 0px 0px 14px 3px rgba(0,0,0,.1);
  --tableBackground: #d3d3d3;

  // Tooltips
  --tooltipShadow: 1px 1px 2px rgba(0,0,0,.15);
  --tooltipBorder: 1px solid #6d6e71;

  //override for CRA default
  -webkit-font-smoothing: unset !important;

  font-family: 'Poppins', sans-serif !important;
  color: var(--darkText);
}

body {
  background: var(--pageBackground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.page-wrap {
  min-height: calc(80vh - 70px);
}

h1 {
  margin: 0;
  padding-bottom: .5em;
}

select {
  cursor: pointer;
}

.card-basic {
  border-radius: var(--cardBorderRadius);
  -webkit-box-shadow: var(--cardShadow);
  -moz-box-shadow: var(--cardShadow);
  box-shadow: var(--cardShadow);
}

.card-inset {
  border-radius: var(--cardBorderRadius);
  -webkit-box-shadow: var(--insetCardShadow);
  -moz-box-shadow: var(--insetCardShadow);
  box-shadow: var(--insetCardShadow);
}

.text-light {
  font-weight: light;
  color: var(--lightText);
  font-size: 12px;
}

.text-red {
  color: var(--warning);
}

.text-blue {
  color: var(--primary);
}

/*********
*
* BUTTONS START
*
**********/
button {
  padding: 10px 20px;
  border-style: solid;
  border-width: 1px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: var(--cardBorderRadius);
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;

  &:hover {
    color: white;
    cursor: pointer;
    border-color: rgba(0, 57, 117, 0.75);
    background-color: rgba(0, 57, 117, 0.75);
  }

  &.disabled {
      background-color: var(--lightText);
      border-color: var(--lightText);
      cursor: not-allowed;
  }

  &.light {
    border-color: #000000bf;
    background-color: #000000bf;
    color: white;

    &:hover {
      color: var(--darkText);
      background-color: rgb(241, 241, 241);
    }
  }

  &.outline {
    border-color: var(--primary);
    background-color: transparent;
    color: var(--primary);

    &:hover {
      color: var(--white);
      background-color: var(--primary);
    }
  }

  &.red {
    border-color: var(--warning);
    background-color: var(--warning);
    color: white;

    &:hover {
      background-color: rgb(172,20,26,.75);
    }
  }

  &.link-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--primary);

    &:hover {
      color: var(--darkText);
      text-decoration: underline;
    }
  }
}
/* 
*
* TOOLTIPS START
*
**********/
.info {
  position: relative;
  cursor: pointer;
  color: var(--primary);
  z-index: 2;

  &.info-blank {
    visibility: hidden;
  }
}
/* TOOLTIPS END */

/*********
*
* MODAL START
*
**********/

.modal-overlay {
  height: 100vh;
  width: 100vw;
  z-index: 99;
  background-color: var(--modalOverlay);
  position: absolute;
  top: 0;
  left: 0;
}

/* MODAL END */

/*********
*
* TABLES START
*
**********/
.table-wrapper {
  max-width: calc(100% - 1.5em);
  width: fit-content;
  overflow-x: scroll;
  padding: 1.5em .7em;
  margin-bottom: 1em;
  box-shadow: var(--tableBoxShadow);
  border-radius: var(--cardBorderRadius);

  // &::-webkit-scrollbar {
  //   height: 8px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: var(--tableBackground);
  //   border-radius: var(--cardBorderRadius);
  //   width: 8px;
  // }

  table, th, td {
    border-collapse: collapse;
    padding: 3px;
    font-size: 12px;
  }
  th, td {
    border: 1px solid var(--tableBackground);
  }

  table {

    /* fixed cells */
    &.fixed-cells {
      table-layout: fixed;
      width: max-content;

      td {
        width: 65px;
      }
    }
    /* fixed cells end */

    /* for no outer border */
    &.no-outer-border {

      tr:first-child {
        th {
          border-top: 0;
        }
      }

      tr:last-child {
        td {
          border-bottom: 0;
        }
      }

      tr {
        td:first-child,
        th:first-child {
          border-left: 0;
        }

        td:last-child,
        td:first-child {
          border-right: 0;
        }
      }
    }
  }
}
/* TABLES END */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }