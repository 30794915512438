table tbody tr td {
  padding: 0;
  input {
    border: none;
    width: 100%;
    height: 100%;
    text-align: right;

    &:focus {
      outline: none;
    }
  }
}