.SubmissionForm {
  background-color: var(--white);
  height: calc(100vh - 75px);
  max-width: 33.3%;
  width: 33.3%;
  // make this a standard shadow
  -webkit-box-shadow: 10px 0px 25px -5px rgba(0,0,0,0.1);
  -moz-box-shadow: 10px 0px 25px -5px rgba(0,0,0,0.1);
  box-shadow: 10px 0px 25px -5px rgba(0,0,0,0.1);
  position: absolute;
  overflow: auto;
  left: 0;
  top: 75px;
  transition: all .3s linear;
  display: flex;
  flex-direction: column;

  &.slide-left {
    position: absolute;
    left: -33.3%;
  }

  h1 {
    text-align: center;
    padding-bottom: 0;
    padding-top: .5em;
  }

  .terastor-img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
  }

  form {
    padding-top: 1em;
    // height: 100%;
    padding: 1em;

    .SubmissionForm__section {
      // to move and delete
      label {
        font-weight: bold;
        font-size: 13px;
      }

      .input-wrapper {
        display: flex;
        // border: 1px solid black;
        // box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        background-color: var(--inputBackgroundColor);
        border-radius: var(--cardBorderRadius);
        padding-right: 5px;
        padding-left: 5px;
        align-items: center;
        color: var(--darkText);
        width: 116px;
        justify-content: center;

        input, select {
          border: none;
          padding: 0;
          margin: 0;
          color: var(--darkText);
          height: 33.5px;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          overflow: auto !important;
          background-color: transparent;
          font-family: 'Poppins', sans-serif !important;
          text-align: center;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &[type=number]{
              -moz-appearance: textfield;
            }
        }
      }
    }

    .input-metric {
      font-size: 11px;
      padding-left: .2em;
    }

    .label-required::after {
      content: "*";
      color: var(--warning);
    }
    
    textarea:focus, input:focus, select:focus {
      outline: none;
    }

  }
    .sticky-submit {
      bottom: 0;
      background-color: var(--white);
      z-index: 3;
      filter: drop-shadow(0 1px 2px rgba(0,0,0,.1)) drop-shadow(0 1px 1px rgba(0,0,0,.06));
      padding: 0 1em 1em 1em;

      .submit-section {
        width: 100%;
        margin-top: 2em;
        background-color: var(--white);
        display: flex;
        justify-content: space-between;
        padding-bottom: .5em;
      }
    }
}